import styled from '@emotion/styled';
import NextLink from 'next/link';
import { ReactNode } from 'react';
import { Theme } from '@emotion/react';
import { NextRouter } from 'next/router';
import { SpanHoverUnderline } from './Span';
import {
  DEVICE_SCREENS_MAX_STRING,
  DEVICE_SCREENS_MIN_STRING,
} from '../../lib/consts';
import SearchSVG from '../../public/img/search.svg';
import CloseSVG from '../../public/img/close.svg';
import { HistoryLastPage } from '../../models/history';

export function Link({
  href = '',
  isBlank = false,
  children,
  ...restProps
}: {
  children: ReactNode;
  href: string;
  isBlank?: boolean;
  [restProps: string]: any;
}) {
  return (
    <NextLink href={href} {...restProps}>
      <a
        href={href}
        {...(isBlank && { rel: 'noreferrer' })}
        {...(isBlank && { target: '_blank' })}
        {...restProps}
        css={{
          cursor: 'pointer',
        }}
        aria-label='ссылка'
      >
        {children}
      </a>
    </NextLink>
  );
}

export const TransformLink = styled(Link)({
  '&:hover': { transform: 'scale(1.05)', transition: '0.3s' },
});

export function LinkLikeButton({
  href,
  children,
}: {
  href: string;
  children: ReactNode;
}) {
  return (
    <Link
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      css={{
        marginTop: 25,
        marginBottom: 50,
        width: 288,
        fontFamily: 'Golos',
        fontSize: 16,
        fontWeight: 500,
        lineHeight: 16,
        letterSpacing: '0em',
        border: 'none',
        cursor: 'pointer',
        textDecoration: 'none',
        color: '#fff',
        height: '2.56em',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '1.28em',
        padding: '0 1.6em',
        backgroundColor: '#ff4a2e',
        backgroundImage: 'linear-gradient(120deg, #ff4a2e 0%, #de2839 100%)',
        backgroundSize: '500% 500%',
        '&:hover': {
          color: '#fff',
        },
        [DEVICE_SCREENS_MAX_STRING.md]: {
          marginBottom: 0,
        },
      }}
      href={href}
    >
      {children}
    </Link>
  );
}

export function SearchLink({ theme }: { theme: Theme }) {
  return (
    <Link
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      css={{ 'span:hover': { color: theme.colors.white } }}
      href={['', 'search', ''].join('/')}
    >
      <SpanHoverUnderline
        css={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          opacity: '1',
          padding: '0',
          width: '36px',
          [DEVICE_SCREENS_MIN_STRING.lg]: {
            width: '100%',
            padding: '20px 15px 10px 15px',
          },
        }}
      >
        <SearchSVG width='20px' height='20px' />
        <span
          css={{
            display: 'none',
            [DEVICE_SCREENS_MIN_STRING.lg]: {
              display: 'inline',
            },
          }}
        >
          Поиск
        </span>
      </SpanHoverUnderline>
    </Link>
  );
}

export function CloseSearch({
  router,
  lastPage,
}: {
  router: NextRouter;
  lastPage: null | HistoryLastPage;
}) {
  return (
    <button
      type='button'
      onClick={() => {
        if (!lastPage || lastPage.isErrorPage) {
          router.push('/');
        } else {
          router.back();
        }
      }}
    >
      <SpanHoverUnderline
        css={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          opacity: '1',
          padding: '0',
          width: '36px',
          [DEVICE_SCREENS_MIN_STRING.lg]: {
            width: '100%',
            padding: '20px 15px 10px 15px',
          },
        }}
      >
        <CloseSVG width='20px' height='20px' />
        <span
          css={{
            display: 'none',
            [DEVICE_SCREENS_MIN_STRING.lg]: {
              display: 'inline',
            },
          }}
        >
          Поиск
        </span>
      </SpanHoverUnderline>
    </button>
  );
}
