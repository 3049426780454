import { EMAIL_REGEXP, PHONE_REGEXP } from './consts';

function email(that, message) {
  return this.test('email', message, (value) => {
    if (!value) {
      return true;
    }
    return (
      new RegExp(EMAIL_REGEXP).test(value) ||
      new RegExp(PHONE_REGEXP).test(value)
    );
  });
}

export function emailValidator(that) {
  return this.test(that, 'Неверно введён E-mail', (value) => {
    if (!value) {
      return true;
    }
    return new RegExp(EMAIL_REGEXP).test(value);
  });
}

export function phoneValidator(that) {
  return this.test(that, 'Неправильно введён телефон', (value) => {
    if (!value) {
      return true;
    }
    return new RegExp(PHONE_REGEXP).test(value);
  });
}

export default email;
