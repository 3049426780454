import { useTheme } from '@emotion/react';
import { Control, useController } from 'react-hook-form';
import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form/dist/types/form';

function Checkbox({
  id,
  name,
  control,
  labelText,
  registerProps,
}: {
  id: string;
  name: string;
  control: Control;
  labelText: string;
  registerProps: UseFormRegisterReturn<typeof name>;
}) {
  const theme = useTheme();
  const { field } = useController({ name, control });
  return (
    <div
      css={{
        position: 'relative',
        width: '100%',
        display: 'flex',
        alignItems: 'end',
      }}
    >
      <div
        css={{
          display: 'flex',
          alignItems: 'flex-start',
          width: 14,
          marginRight: 8,
        }}
      >
        <input
          id={id}
          type='checkbox'
          className='checkbox-mini'
          // checked
          {...registerProps}
        />
        <label htmlFor={id} />
      </div>
      <div
        css={{
          fontSize: '.8em',
          fontWeight: 400,
          lineHeight: 1.35,
          color: '#aaa',
        }}
      >
        {labelText}
      </div>
    </div>
  );
}
export default Checkbox;
