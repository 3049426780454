import { useState } from 'react';
import { useController } from 'react-hook-form';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import EyeOpen from '../../public/img/eye-open.svg';
import EyeClose from '../../public/img/eye-close.svg';
import CheckSVG from '../../public/img/fill-check.svg';
import CloseSVG from '../../public/img/fill-close.svg';
import errorCircleImg from '../../public/img/error_circle.png';
import correctCircleImg from '../../public/img/correct_circle.png';

function Input({
  text,
  type = 'text',
  control,
  registerProps,
  name,
  error = false,
  errorMessage = '',
  maxLength = 0,
  showPrefix = false,
  secondaryText = '',
}) {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const fieldState = useController({ name, control });
  function getType() {
    if (type === 'password' && isPasswordVisible) {
      return 'text';
    }
    return type;
  }
  return (
    <div
      css={{
        padding: '.5em 0',
      }}
    >
      <div
        className='input-wrapper'
        css={{
          position: 'relative',
          width: '100%',
          fontSize: '1rem',
          lineHeight: 1,
          fontWeight: 400,
          padding: '10px 0 12px',
          '&:has(> input:focus)': {
            ...(showPrefix && {
              '&::before': {
                content: '"+7"',
                position: 'absolute',
                bottom: 20,
              },
            }),
          },
          ...(showPrefix && fieldState.field.value
            ? {
                '&::before': {
                  content: '"+7"',
                  position: 'absolute',
                  bottom: 20,
                },
              }
            : {}),
        }}
      >
        <input
          css={{
            padding: '.5em 0',
            width: '100%',
            height: 30,
            fontFamily: '"Golos", Arial',
            fontSize: '1rem',
            lineHeight: 1,
            fontWeight: 400,
            color: '#282828',
            borderTop: 'none',
            borderLeft: 'none',
            borderRight: 'none',
            borderBottom: '1px solid #DCDEDA',
            backgroundColor: 'transparent',
            '&:focus + div, &:not(:placeholder-shown) + div': {
              fontSize: '.75em',
              top: 6,
              color: '#979996',
            },
          }}
          type={getType()}
          placeholder=' '
          {...(!!maxLength && { maxLength })}
          {...registerProps}
        />
        <div
          css={{
            pointerEvents: 'none',
            position: 'absolute',
            lineHeight: 1,
            top: '50%',
            left: 0,
            color: '#BFC1C3',
            transition: 'all ease-in .15s',
            marginTop: '-0.5em',
            cursor: 'text',
          }}
        >
          {text}
        </div>
        {secondaryText !== '' &&
          (error ? (
            <div
              css={{
                textAlign: 'left',
                position: 'absolute',
                top: 42,
                left: 0,
                fontSize: '.75em',
                color: '#f44336',
              }}
            >
              {errorMessage}
            </div>
          ) : (
            <div
              css={{
                textAlign: 'left',
                position: 'absolute',
                top: 42,
                left: 0,
                fontSize: '.75em',
                color: '#7D7B7A',
              }}
            >
              {secondaryText}
            </div>
          ))}
        {error && !secondaryText && (
          <div
            css={{
              textAlign: 'center',
              position: 'absolute',
              bottom: 0,
              right: 0,
              fontSize: '.75em',
              color: '#f44336',
            }}
          >
            {errorMessage}
          </div>
        )}
        {type === 'password' && (
          <div
            css={{
              position: 'absolute',
              top: 14,
              right: 20,
              height: 21,
              width: 21,
              color: '#282828',
            }}
          >
            {isPasswordVisible && (
              <EyeOpen onClick={() => setIsPasswordVisible(false)} />
            )}
            {!isPasswordVisible && (
              <EyeClose onClick={() => setIsPasswordVisible(true)} />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export function SubscribeInput({
  control,
  name,
  registerProps,
  placeholder,
  defaultStyle,
}) {
  const theme = useTheme();
  const { fieldState } = useController({ name, control });
  return (
    <input
      placeholder={placeholder}
      {...registerProps}
      css={{
        fontFamily: 'Golos',
        position: 'relative',
        fontSize: 14,
        fontWeight: 500,
        textAlign: 'left',
        outline: 0,
        color: theme.colors.white,
        border: '2px solid #dadada',
        borderRadius: '4px 0 0 4px',
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        borderColor: 'transparent',
        width: 'calc(100% - 80px)',
        marginBottom: 0,
        '::placeholder': {
          color: theme.colors.white,
        },
        lineHeight: '46px',
        padding: '0 2.5em 0 1.4em',
        height: 50,
        borderRight: 'none',
        ...((fieldState.isTouched || fieldState.isDirty) && fieldState.invalid
          ? {
              backgroundImage: `url(${errorCircleImg.src})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'calc(100% - 1em) 50%',
              backgroundSize: '1.4em',
            }
          : {}),
        ...(fieldState.isDirty && !fieldState.invalid
          ? {
              backgroundImage: `url(${correctCircleImg.src})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'calc(100% - 1em) 50%',
              backgroundSize: '1.4em',
            }
          : {}),
        ...(defaultStyle
          ? {
              color: theme.colors.black,
              backgroundColor: 'rgba(0, 0, 0, 0.075)',
              borderColor: 'transparent',
              '::placeholder': {
                color: '#999',
              },
            }
          : {}),
      }}
    />
  );
}

const StyledInputV2 = styled.input({
  width: '100%',
  height: 50,
  border: '2px solid #dadada',
  padding: '0 35px 0 20px',
  borderRadius: 4,
  fontFamily: 'Golos',
  fontWeight: 500,
  '::placeholder': {
    color: '#ccc',
    fontWeight: 500,
  },
  ':not(:placeholder-shown)': {
    padding: '0 35px',
  },
});

export function InputV2({
  placeholder,
  control,
  registerProps,
  name,
  error = false,
  type = 'text',
}) {
  const fieldState = useController({ name, control });
  return (
    <div css={{ position: 'relative', width: '100%' }}>
      <StyledInputV2 type={type} placeholder={placeholder} {...registerProps} />
      {fieldState.field.value !== null && (
        <div>
          {error ? (
            <CloseSVG
              css={{
                width: 20,
                height: 20,
                position: 'absolute',
                top: 15,
                right: 15,
              }}
            />
          ) : (
            <CheckSVG
              css={{
                width: 20,
                height: 20,
                position: 'absolute',
                top: 15,
                right: 15,
              }}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default Input;
