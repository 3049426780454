import { useTheme } from '@emotion/react';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useState } from 'react';
import { DateEvent } from '../../models/action';
import checkImg from '../../public/img/check.png';

const weekDays = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];

function Calendar({
  initPermittedDates,
  latestEventDate,
  nearestEventDate,
  onDatePicked,
  onMonthChanged,
  enableMultiSelection = true,
  additionalCss,
  enableClear = false,
  initDates,
}: {
  initPermittedDates?: string[];
  latestEventDate?: DateEvent | null;
  nearestEventDate?: DateEvent | null;
  onDatePicked?: (dates: [string, string] | null) => void;
  onMonthChanged?: (month: number, year: number) => Promise<string[]>;
  additionalCss?: object;
  enableMultiSelection?: boolean;
  enableClear?: boolean;
  initDates?: [string, string] | null;
}) {
  const theme = useTheme();
  const [calendarState, setCalendarState] = useState<{
    startDate: null | DateTime;
    finishDate: null | DateTime;
    modeMulti: boolean;
  }>({ startDate: null, finishDate: null, modeMulti: false });
  const [delta, setDelta] = useState<DateTime | null>(null); // first day of selected month
  const [permittedDates, setPermittedDates] = useState(initPermittedDates); // first day of selected month
  const [weekCount, setWeekCount] = useState<{
    monthDataTable: (DateTime & { disabled?: boolean | undefined })[][];
    disabled: boolean;
  }>({ monthDataTable: [], disabled: false });
  function getWeekCount(deltaValue: DateTime) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    let deltaCopy: DateTime = new DateTime(deltaValue);
    const { month } = deltaCopy;
    const weeks = [];
    const dayInWeeks = [];
    while (deltaCopy.month === month) {
      if (weeks.indexOf(deltaCopy.weekNumber) === -1) {
        weeks.push(deltaCopy.weekNumber);
        dayInWeeks.push(dayInWeeks.length * 7);
      }
      deltaCopy = deltaCopy.plus({ days: 1 });
    }
    const monthDataTable = dayInWeeks.map((firstDay) =>
      Array(7)
        .fill(0)
        .map((_, indexOfDay) => {
          const curDate: DateTime & { disabled?: boolean } = deltaValue
            .startOf('week')
            .plus({ days: indexOfDay + firstDay });
          curDate.disabled =
            curDate.month !== deltaValue.month ||
            (permittedDates &&
              !permittedDates.includes(curDate.toFormat('yyyy-LL-dd')));
          return curDate;
        })
    );
    const isTableDisabled =
      monthDataTable.filter((week) => week.some((day) => !day.disabled))
        .length === 0;
    return { monthDataTable, disabled: isTableDisabled };
  }
  const calcWeekCount = useCallback(() => {
    if (delta) {
      setWeekCount(getWeekCount(delta));
    }
  }, [delta]);
  function onClickDate(
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    value: DateTime & { disabled?: boolean | undefined },
    multi = false
  ) {
    event.stopPropagation();
    if (value.disabled) {
      return;
    }
    if (multi) {
      let newStartDate = calendarState.finishDate
        ? value
        : calendarState.startDate;
      let newFinishDate =
        !newStartDate || calendarState.finishDate ? null : value;
      newStartDate = newStartDate || value;
      if (newStartDate && newFinishDate && newStartDate > newFinishDate) {
        [newStartDate, newFinishDate] = [newFinishDate, newStartDate];
      }
      if (
        enableClear &&
        newFinishDate &&
        newFinishDate.toISODate() === newStartDate.toISODate()
      ) {
        setCalendarState((oldState) => ({
          ...oldState,
          startDate: null,
          finishDate: null,
        }));
        if (onDatePicked) {
          onDatePicked(null);
        }
        return;
      }
      setCalendarState((oldState) => ({
        ...oldState,
        startDate: newStartDate,
        finishDate: newFinishDate,
      }));
      if (onDatePicked && newFinishDate && newStartDate) {
        onDatePicked([
          newStartDate.toFormat('dd.LL.yyyy'),
          newFinishDate.toFormat('dd.LL.yyyy'),
        ]);
      }
    } else {
      if (
        enableClear &&
        calendarState.startDate &&
        value.toISODate() === calendarState.startDate.toISODate()
      ) {
        setCalendarState((oldState) => ({
          ...oldState,
          startDate: null,
          finishDate: null,
        }));
        if (onDatePicked) {
          onDatePicked(null);
        }
        return;
      }
      setCalendarState((oldState) => ({
        ...oldState,
        startDate: value,
        finishDate: value,
      }));
      if (onDatePicked) {
        onDatePicked([
          value.toFormat('dd.LL.yyyy'),
          value.toFormat('dd.LL.yyyy'),
        ]);
      }
    }
  }
  function isActive(date: DateTime & { disabled?: boolean | undefined }) {
    return (
      calendarState.startDate &&
      date &&
      (calendarState.startDate.toISODate() === date.toISODate() ||
        (calendarState.finishDate &&
          calendarState.finishDate.toISODate() === date.toISODate()))
    );
  }
  function isInterval(_date: DateTime & { disabled?: boolean }) {
    return (
      calendarState.startDate &&
      calendarState.finishDate &&
      calendarState.startDate <= _date &&
      calendarState.finishDate >= _date
    );
  }
  function initDate(dates: string[] | string) {
    if (!dates) {
      return;
    }
    // 18.08.2023
    const datesInterval: string[] = [];
    if (Array.isArray(dates)) {
      datesInterval.push(...dates);
    } else {
      datesInterval.push(dates);
    }
    const [startDate, finishDate] = datesInterval.map((dateString) =>
      DateTime.fromFormat(dateString, 'dd.LL.yyyy')
    );
    const dayBetween = finishDate.diff(startDate, 'days').days;
    if (dayBetween && finishDate) {
      setCalendarState({
        startDate,
        finishDate,
        modeMulti: true,
      });
    } else {
      setCalendarState({
        startDate,
        finishDate,
        modeMulti: false,
      });
    }
    calcWeekCount();
  }

  function getFutureMonthDate(increment: number) {
    return delta!.plus({ month: increment });
  }
  function isActiveNextMonthBtn() {
    if (!permittedDates || !latestEventDate) {
      return true;
    }
    let latestDate = DateTime.fromFormat(
      latestEventDate.start_date,
      'yyyy-LL-dd'
    );
    if (latestEventDate.time.length) {
      const [hour, minute] = latestEventDate.time[0]
        .split(':')
        .map((v) => Number(v));
      latestDate = latestDate.set({ hour });
      latestDate = latestDate.set({ minute });
    }
    const firstDayOfMonth = getFutureMonthDate(+1).startOf('month');
    return firstDayOfMonth <= latestDate;
  }
  function isActivePrevMonthBtn() {
    if (!permittedDates || !nearestEventDate) {
      return true;
    }
    let nearestDate = DateTime.fromFormat(
      nearestEventDate.start_date,
      'yyyy-LL-dd'
    );
    if (nearestEventDate.time.length) {
      const [hour, minute] = nearestEventDate.time[0]
        .split(':')
        .map((v) => Number(v));
      nearestDate = nearestDate.set({ hour });
      nearestDate = nearestDate.set({ minute });
    }
    const lastDayOfMonth = getFutureMonthDate(-1).endOf('month');
    return lastDayOfMonth >= nearestDate;
  }
  function onClickChangeMonth(increment: number) {
    const futureMonth = getFutureMonthDate(increment);
    if (onMonthChanged) {
      onMonthChanged(futureMonth.month, futureMonth.year).then(
        (eventDatesCalendar) => {
          setPermittedDates(eventDatesCalendar);
          setDelta(futureMonth.set({ day: 1 }));
          calcWeekCount();
        }
      );
    } else {
      setDelta(futureMonth.set({ day: 1 }));
      calcWeekCount();
    }
  }
  // init effect
  useEffect(() => {
    if (initDates) {
      initDate(initDates);
    }
    if (!delta) {
      if (permittedDates?.length) {
        setDelta(
          DateTime.fromFormat(permittedDates[0], 'yyyy-LL-dd').set({ day: 1 })
        );
      } else {
        setDelta(DateTime.now().set({ day: 1 }));
      }
    }
    calcWeekCount();
  }, [permittedDates, calcWeekCount, delta]);

  if (!delta) {
    return null;
  }
  return (
    <div
      onClick={(e) => e.stopPropagation()}
      // calendar
      // popup__calendar filter
      css={{
        fontFamily: 'Golos',
        cursor: 'default',
        lineHeight: '1.4',
        ...additionalCss,
      }}
    >
      <div
        // calendar__month
        css={{
          display: 'flex',
          alignItems: 'baseline',
          marginBottom: 15,
          justifyContent: 'center',
          // justifyContent: 'space-between', form
        }}
      >
        <div
          // calendar__month-control-left
          onClick={(event) => {
            event.stopPropagation();
            if (isActivePrevMonthBtn()) {
              onClickChangeMonth(-1);
            }
          }}
          css={{
            marginRight: 20,
            padding: '0 5px',
            cursor: 'pointer',
            ...(!isActivePrevMonthBtn()
              ? {
                  cursor: 'auto',
                  opacity: 0.5,
                }
              : {}),
          }}
        >
          <svg
            className='icon icon_white icon_xsm'
            width='7'
            height='12'
            viewBox='0 0 7 12'
            fill='none'
          >
            <path
              d='M6 1L1 5.85714L6 11'
              stroke='#333333'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        </div>
        <div
          // calendar__month-this
          css={{
            fontSize: 16,
            fontWeight: 500,
            lineHeight: '1.56',
            letterSpacing: '1px',
            color: theme.colors.black,
            textTransform: 'capitalize',
          }}
        >
          {delta.toFormat('LLLL', { locale: 'ru' })}
        </div>
        <div
          // calendar__month-control-right
          onClick={(event) => {
            event.stopPropagation();
            if (isActiveNextMonthBtn()) {
              onClickChangeMonth(+1);
            }
          }}
          css={{
            marginLeft: 20,
            padding: '0 5px',
            cursor: 'pointer',
            ...(!isActiveNextMonthBtn()
              ? {
                  cursor: 'auto',
                  opacity: 0.5,
                }
              : {}),
          }}
        >
          <svg
            className='icon icon_white icon_xsm'
            width='7'
            height='12'
            viewBox='0 0 7 12'
            fill='none'
          >
            <path
              d='M1 1L6 5.85714L1 11'
              stroke='#333333'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        </div>
      </div>
      <div
        // calendar__week
        css={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {weekDays.map((weekDay, index) => (
          <div
            // calendar__date
            css={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: 30,
              backgroundColor: theme.colors.white,
              color: theme.colors.black,
              cursor: 'pointer',
              ...(index > 4 ? { color: theme.colors.red } : {}),
            }}
            key={weekDay}
          >
            <div
              // calendar__day
              css={{
                fontSize: 16,
                fontWeight: 700,
              }}
            >
              {weekDay}
            </div>
          </div>
        ))}
      </div>
      {weekCount.monthDataTable.map((week) => (
        <div
          key={week.toString()}
          // calendar__week
          css={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {week.map((dateItem, index) => (
            <div
              onClick={(event) =>
                onClickDate(event, dateItem, calendarState.modeMulti)
              }
              key={dateItem.toMillis()}
              css={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: 30,
                backgroundColor: theme.colors.white,
                color: theme.colors.black,
                cursor: 'pointer',
                ...(index > 4 ? { color: theme.colors.red } : {}),
                ...(isActive(dateItem)
                  ? {
                      backgroundColor: theme.colors.red,
                      color: `${theme.colors.white} !important`,
                    }
                  : {}),
                ...(calendarState.modeMulti && isInterval(dateItem)
                  ? {
                      backgroundColor: theme.colors.red,
                      color: theme.colors.white,
                    }
                  : {}),
                ...(dateItem.disabled
                  ? { opacity: 0.3, cursor: 'default' }
                  : {}),
              }}
              // calendar__date
            >
              <div
                // calendar__number
                css={{
                  fontSize: 16,
                }}
              >
                {dateItem.toFormat('d')}
              </div>
            </div>
          ))}
        </div>
      ))}
      {enableMultiSelection && (
        <span
          onClick={() => {
            setCalendarState({
              modeMulti: !calendarState.modeMulti,
              startDate: null,
              finishDate: null,
            });
          }}
          className='popup__calendar-link-control'
          // popup__calendar-link-control
          css={{
            display: 'block',
            fontSize: 16,
            fontWeight: 500,
            textAlign: 'left',
            color: theme.colors.black,
            textDecoration: 'none',
            marginTop: 10,
            cursor: 'pointer',
            paddingLeft: 45,
            '&:hover': {
              color: 'rgba(0, 0, 0, 0.5)',
            },
            '&:before': {
              display: 'block',
              content: '""',
              position: 'absolute',
              marginLeft: -30,
              marginTop: 4,
              width: 16,
              height: 16,
              border: `1px solid ${theme.colors.red}`,
              borderRadius: 2,
              ...(calendarState.modeMulti
                ? {
                    background: `${theme.colors.red} url(${checkImg.src}) center center no-repeat`,
                  }
                : {}),
            },
          }}
        >
          выбрать несколько дат
        </span>
      )}
    </div>
  );
}

export default Calendar;
