import styled from '@emotion/styled';

const modificationsStyle = {
  inline: {
    display: 'flex',
    width: '100%',
    maxWidth: '350px',
  },
};

export const Form = styled.form(
  ({ modification }: { modification?: string }) => ({
    display: 'block',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ...modificationsStyle[modification],
  })
);
