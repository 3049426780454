import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { notShowPreloderUrlPath } from './MainLoader';

export const useMainPreloader = () => {
  const router = useRouter();
  const [routerIsLoading, setRouterIsLoading] = useState(false);
  useEffect(() => {
    let timer: null | ReturnType<typeof setTimeout> = null;
    function start(e: string) {
      // do not show the main preloader if sorting and filtering are used (the root URL does not change)
      const transitionUrlArr = e.split('/').filter((el) => el !== '');
      const pathnameArr = router.pathname.split('/').filter((el) => el !== '');
      if (transitionUrlArr.length > 1) transitionUrlArr.shift();
      if (pathnameArr.length > 1) pathnameArr.shift();
      timer = setTimeout(() => {
        if (
          transitionUrlArr.length > 0 &&
          pathnameArr.length > 0 &&
          notShowPreloderUrlPath.includes(pathnameArr[0]) &&
          notShowPreloderUrlPath.includes(transitionUrlArr[0]) &&
          transitionUrlArr[0] === pathnameArr[0]
        ) {
          setRouterIsLoading(false);
        } else {
          setRouterIsLoading(true);
        }
      }, 500);
    }
    function end() {
      if (timer) clearTimeout(timer);
      setRouterIsLoading(false);
    }
    if (timer) clearTimeout(timer);
    setRouterIsLoading(false);
    router.events.on('routeChangeStart', start);
    router.events.on('routeChangeComplete', end);
    router.events.on('routeChangeError', end);
    return () => {
      router.events.off('routeChangeStart', start);
      router.events.off('routeChangeComplete', end);
      router.events.off('routeChangeError', end);
      if (timer) clearTimeout(timer);
      setRouterIsLoading(false);
    };
  }, []);

  return { routerIsLoading };
};
