import { useTheme } from '@emotion/react';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Container } from './Container';
import LogoSVG from '../../public/img/gor_logo.svg';
import ArrowUpSVG from '../../public/img/arrow-fill.svg';
import LocationSVG from '../../public/img/location.svg';
import CatalogSVG from '../../public/img/catalog.svg';
import CalendarSVG from '../../public/img/calendar.svg';
import SearchSVG from '../../public/img/search.svg';
import PersonSVG from '../../public/img/person.svg';
import CompilationSVG from '../../public/img/compilation.svg';
import FavoriteSVG from '../../public/img/favorite.svg';
import MenuSVG from '../../public/img/menu.svg';
import CloseSVG from '../../public/img/close.svg';
import VKSVG from '../../public/img/vk.svg';
import TelegramSVG from '../../public/img/telegram.svg';
import WhatsAppSVG from '../../public/img/whatsapp.svg';
import EmailSVG from '../../public/img/email.svg';
import AngleDownSVG from '../../public/img/angle-down.svg';
import TriangleDownSVG from '../../public/img/triangle-down.svg';
import { CloseSearch, Link, SearchLink, TransformLink } from '../styled/Link';
import {
  DEVICE_SCREENS,
  DEVICE_SCREENS_MAX_STRING,
  DEVICE_SCREENS_MIN_STRING,
} from '../../lib/consts';
import { SpanHoverUnderline } from '../styled/Span';
import { useModal } from '../../providers/modal';
import { useCitySelect } from '../../hooks/city';
import { City } from '../../models';

import {
  ModalContent,
  ModalContentWrapper,
  ModalTitle,
  ModalTitleWrapper,
} from '../common/Modal';
import { useFavorite } from '../../providers/favorite';
import Calendar from '../calendar/calendar';
import LoginModalModalForm from '../login/login';
import useOutsideClick from '../../hooks/outside-click';
import { getEventTextFromCount } from '../../utils';
import { http } from '../../lib/api/http';
import { V4 } from '../../lib/api/urls';
import { useAuthorization } from '../../providers/authorization';
import { Category } from '../../models/categories';
import { useHistory } from '../../providers/history';
import { useLoading } from '../../providers/loading';

function CalendarComponent({
  currentCity,
  setIsShowCalendar,
  hideModal,
}: {
  currentCity: City;
  setIsShowCalendar: (status: boolean) => void;
  hideModal?: () => void;
}) {
  const theme = useTheme();
  const router = useRouter();
  const {
    query,
  }: {
    query: {
      start_date2?: [string, string];
    };
  } = router;
  const [totalCalendarPosts, setTotalCalendarPosts] = useState(0);
  const [startDate, setStartDate] = useState<string[] | null>(null);
  function onCalendarDateChanged(dates: [string, string] | null) {
    if (dates) {
      setStartDate(dates);
      const startDate2 = (dates || []).join('-');
      const [dateFrom, dateTo] = startDate2
        .split('-')
        .map((dateStr) => dateStr.split('.').reverse().join('-'));
      http
        .get(V4.events, {
          offset: 0,
          limit: 1,
          is_web: true,
          status: 'active',
          city: currentCity.id,
          date_from: dateFrom,
          date_to: dateTo,
        })
        .then((response: { count: number }) =>
          setTotalCalendarPosts(response.count)
        );
    } else {
      setStartDate(null);
      setTotalCalendarPosts(0);
    }
  }
  function onCalendarSubmit() {
    setIsShowCalendar(false);
    setStartDate(null);
    setTotalCalendarPosts(0);
    // this.metrika('calendarShow');
    if (router.asPath.indexOf('/catalog/') !== -1) {
      const currentUrl = new URL(window.location.href);
      const searchParams = new URLSearchParams(currentUrl.search);
      searchParams.delete('start_date2');
      if (startDate) {
        startDate.forEach((item) => searchParams.append('start_date2', item));
      }
      currentUrl.search = searchParams.toString();
      router.replace(currentUrl.href);
      if (hideModal) {
        hideModal();
      }
    } else {
      router.push({
        pathname: ['', currentCity.url, 'catalog', ''].join('/'),
        query: { start_date2: startDate, sort: 'popular' },
      });
      if (hideModal) {
        hideModal();
      }
    }
  }
  return (
    <>
      <Calendar
        enableClear
        initDates={query.start_date2 as [string, string] | undefined}
        onDatePicked={(dates: [string, string] | null) =>
          onCalendarDateChanged(dates)
        }
      />
      <div
        css={{
          fontFamily: 'Golos',
          textAlign: 'center',
          marginTop: 20,
        }}
      >
        <a
          onClick={() => onCalendarSubmit()}
          css={{
            height: 34,
            display: `inline-flex`,
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: theme.constants.borderRadius,
            padding: '0 1.2em',
            fontSize: '1rem',
            fontWeight: 500,
            whiteSpace: 'normal',
            border: `2px solid ${theme.colors.red}`,
            cursor: 'pointer',
            textDecoration: 'none',
            color: theme.colors.white,
            lineHeight: '41px',
            transition: 'all 0.2s ease',
            backgroundColor: theme.colors.red,

            '&:hover': {
              backgroundColor: theme.colors.white,
              color: theme.colors.black,
            },
          }}
        >
          Показать {totalCalendarPosts || 'все'}{' '}
          {totalCalendarPosts > 0
            ? getEventTextFromCount(totalCalendarPosts)
            : 'события'}
        </a>
      </div>
    </>
  );
}

export function Header({
  currentCity,
  cities,
  categoriesForHeader,
}: {
  currentCity: City;
  cities: { results: City[] };
  categoriesForHeader: Category[];
}) {
  const theme = useTheme();
  const context = useModal();
  const favorities = useFavorite();
  const history = useHistory();
  const authorization = useAuthorization();
  const { CitySelect } = useCitySelect({ currentCity, cities });
  const [isPopupMenuOpen, setIsPopupMenuOpen] = useState(false);
  const [isQuestionOpen, setIsQuestionOpen] = useState(false);
  const [isShowFavoriteTooltip, setIsShowFavoriteTooltip] = useState(false);
  const [isMobileMode, setIsMobileMode] = useState(false);
  const router = useRouter();
  const [isShowCalendar, setIsShowCalendar] = useState(false);
  const outsideRef = useOutsideClick(() => setIsShowCalendar(false));
  const { isLoading } = useLoading();
  useEffect(() => {
    setIsMobileMode(window.innerWidth < DEVICE_SCREENS.sm);
  }, []);
  function onFavoriteClick() {
    if ((favorities && favorities.getCount() > 0) || isMobileMode) {
      router.push('/favorite/');
    } else {
      setIsShowFavoriteTooltip(true);
      setTimeout(() => setIsShowFavoriteTooltip(false), 3000);
    }
  }
  return (
    <header
      css={{
        zIndex: 999,
        minHeight: '60px',
        [DEVICE_SCREENS_MIN_STRING.sm]: {
          minHeight: '70px',
        },
        backgroundColor: theme.colors.primary,
        boxShadow: '0 2px 6px 0 rgb(0 0 0 / 20%)',
        backgroundImage: `linear-gradient(120deg, ${theme.colors.primary} 0%, ${theme.colors.gradient} 100%)`,
        backgroundSize: '500% 500%',
        animation: 'move-background 60s linear infinite',
        color: theme.colors.white,
        position: 'sticky',
        top: '0px',
      }}
    >
      <Container
        className='header'
        css={{
          display: 'flex',
          gap: '0',
          justifyContent: 'space-between',
          [DEVICE_SCREENS_MIN_STRING.sm]: {
            gap: '20px',
            justifyContent: 'start',
          },
          minHeight: 60,
          [DEVICE_SCREENS_MIN_STRING.sm]: {
            minHeight: 70,
          },
        }}
      >
        <div
          css={{
            width: '160px',
            display: isPopupMenuOpen ? 'none' : 'block',
            [DEVICE_SCREENS_MIN_STRING.sm]: {
              display: 'block',
              borderRight: '2px solid rgba(255, 255, 255, 0.1)',
            },
          }}
        >
          <TransformLink
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            css={{
              display: 'flex',
              alignItems: 'center',
              height: '100%',
              padding: '0 20px 5px 15px',
            }}
            href={['', currentCity.url, ''].join('/')}
          >
            <LogoSVG
              css={{
                [DEVICE_SCREENS_MAX_STRING.sm]: {
                  width: '107px',
                  height: '18px',
                },
                width: '122px',
                height: '20px',
              }}
            />
          </TransformLink>
        </div>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div
          css={{
            display: isPopupMenuOpen ? 'flex' : 'none',
            paddingLeft: isPopupMenuOpen ? '20px' : '0',
            [DEVICE_SCREENS_MIN_STRING.sm]: {
              paddingLeft: 20,
              display: 'flex',
            },
            alignItems: 'center',
            cursor: 'pointer',
            flexGrow: 1,
            gap: '10px',
          }}
          onClick={() => {
            if (context) {
              context.setModalState((oldState) => ({
                ...oldState,
                show: true,
                content: (
                  <ModalContentWrapper>
                    <ModalTitleWrapper setModalState={context.setModalState}>
                      <ModalTitle>Выберите город</ModalTitle>
                    </ModalTitleWrapper>
                    <ModalContent>
                      <CitySelect />
                    </ModalContent>
                  </ModalContentWrapper>
                ),
              }));
            }
          }}
        >
          <LocationSVG css={{ width: '20px', height: '20px' }} />
          <SpanHoverUnderline css={{ fontFamily: 'Golos', fontWeight: '500' }}>
            {currentCity.title}
          </SpanHoverUnderline>
          <TriangleDownSVG css={{ width: '12px', height: '12px' }} />
        </div>
        <div
          css={{
            fontFamily: 'Montserrat',
            display: 'none',
            [DEVICE_SCREENS_MIN_STRING.sm]: {
              display: 'flex',
            },
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <div
            css={{
              display: 'flex',
              height: '100%',
              borderLeft: '2px solid rgba(255, 255, 255, 0.1)',
            }}
          >
            <Link
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              css={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                'span:hover': { color: theme.colors.white },
              }}
              href={['', currentCity.url, 'catalog', ''].join('/')}
            >
              <SpanHoverUnderline
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  opacity: '1',
                  padding: '0',
                  width: '36px',
                  [DEVICE_SCREENS_MIN_STRING.lg]: {
                    width: '100%',
                    padding: '20px 15px 10px 15px',
                  },
                }}
              >
                <CatalogSVG width='20px' height='20px' />
                <span
                  css={{
                    display: 'none',
                    [DEVICE_SCREENS_MIN_STRING.lg]: {
                      display: 'inline',
                    },
                  }}
                >
                  Каталог
                </span>
              </SpanHoverUnderline>
            </Link>
          </div>
          <div
            css={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              borderLeft: '2px solid rgba(255, 255, 255, 0.1)',
            }}
          >
            {router.pathname !== '/search' ? (
              <SearchLink theme={theme} />
            ) : (
              <CloseSearch router={router} lastPage={history.lastPage} />
            )}
          </div>
          <div
            css={{
              display: 'flex',
              height: '100%',
              borderLeft: '2px solid rgba(255, 255, 255, 0.1)',
              position: 'relative',
            }}
            onClick={() => setIsShowCalendar(!isShowCalendar)}
          >
            <SpanHoverUnderline
              css={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                opacity: '1',
                padding: '0',
                width: '36px',
                [DEVICE_SCREENS_MIN_STRING.lg]: {
                  width: '100%',
                  padding: '20px 15px 10px 15px',
                },
              }}
            >
              <CalendarSVG width='20px' height='20px' />
              <span
                css={{
                  display: 'none',
                  [DEVICE_SCREENS_MIN_STRING.lg]: {
                    display: 'inline',
                  },
                }}
              >
                Календарь
              </span>
            </SpanHoverUnderline>
            {isShowCalendar && (
              <div
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                ref={outsideRef}
                css={{
                  backgroundColor: theme.colors.white,
                  padding: '20px 30px 20px 30px',
                  width: 400,
                  textAlign: 'center',
                  color: theme.colors.black,
                  borderRadius: theme.constants.borderRadius,
                  boxShadow: '0 15px 40px 0 rgba(0, 0, 0, 0.5)',
                  zIndex: 999,
                  maxHeight: '90vh',
                  [DEVICE_SCREENS_MAX_STRING.sm]: {
                    maxHeight: '100vh',
                    padding: '30px 30px 20px 30px',
                    transform: 'none',
                    borderRadius: 0,
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    top: 0,
                    right: 0,
                    overflow: 'auto',
                    width: '100%',
                  },
                  position: 'absolute',
                  right: -28,
                  top: 60,
                  cursor: 'default',
                  '&:before': {
                    position: 'absolute',
                    border: 'solid',
                    content: '""',
                    borderColor: `${theme.colors.white} transparent`,
                    borderWidth: '0px 9px 9px 9px',
                    top: -6,
                    right: 50,
                    zIndex: 998,

                    [DEVICE_SCREENS_MAX_STRING.sm]: {
                      display: 'none',
                    },
                  },
                }}
              >
                <CalendarComponent
                  currentCity={currentCity}
                  setIsShowCalendar={setIsShowCalendar}
                />
              </div>
            )}
          </div>
          <div
            css={{
              position: 'relative',
              display: 'flex',
              height: '100%',
              borderLeft: '2px solid rgba(255, 255, 255, 0.1)',
            }}
          >
            <SpanHoverUnderline
              onClick={() => onFavoriteClick()}
              css={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                opacity: '1',
                padding: '0',
                width: '36px',
                [DEVICE_SCREENS_MIN_STRING.lg]: {
                  width: '100%',
                  padding: '20px 15px 10px 15px',
                },
              }}
            >
              <FavoriteSVG width='20px' height='20px' />
              <span
                css={{
                  display: 'none',
                  [DEVICE_SCREENS_MIN_STRING.lg]: {
                    display: 'inline',
                  },
                }}
              >
                Избранное
              </span>
              {favorities && favorities.getCount() > 0 && (
                <div
                  css={{
                    position: 'absolute',
                    top: 15,
                    right: 8,
                    background: theme.colors.white,
                    color: theme.colors.black,
                    borderRadius: '50%',
                    width: 20,
                    height: 20,
                    textAlign: 'center',
                    fontSize: 12,
                    fontWeight: 'bold',
                    lineHeight: '20px',
                    [DEVICE_SCREENS_MAX_STRING.lg]: {
                      top: 17,
                      right: 4,
                      width: 14,
                      height: 14,
                      fontSize: 10,
                      lineHeight: '14px',
                    },
                  }}
                >
                  {favorities.getCount()}
                </div>
              )}
            </SpanHoverUnderline>
            {isShowFavoriteTooltip && (
              <div
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  position: 'absolute',
                  width: 'max-content',
                  right: -81,
                  top: 65,
                }}
              >
                <ArrowUpSVG css={{ margin: '0 auto', width: 20, height: 10 }} />
                <div
                  css={{
                    ...theme.mixins.fonts.golos,
                    fontSize: 12,
                    lineHeight: '15.6px',
                    color: theme.colors.text,
                    padding: 16,
                    background: theme.colors.white,
                    borderRadius: 2,
                  }}
                >
                  Вы ещё ничего не добавили в избранное
                </div>
              </div>
            )}
          </div>
          <div
            css={{
              display: 'flex',
              height: '100%',
              borderLeft: '2px solid rgba(255, 255, 255, 0.1)',
            }}
          >
            <SpanHoverUnderline
              onClick={() => {
                if (authorization.isReady) {
                  if (context && !authorization.isAuthenticated) {
                    context.setModalState({
                      content: (
                        <LoginModalModalForm
                          setModalState={context.setModalState}
                          currentCity={currentCity}
                        />
                      ),
                      show: true,
                    });
                  } else {
                    router.push('/profile');
                  }
                }
              }}
              css={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                opacity: '1',
                padding: '0',
                width: '36px',
                [DEVICE_SCREENS_MIN_STRING.lg]: {
                  width: '100%',
                  padding: '20px 15px 10px 15px',
                },
              }}
            >
              <PersonSVG width='20px' height='20px' />
              <span
                css={{
                  display: 'none',
                  [DEVICE_SCREENS_MIN_STRING.lg]: {
                    display: 'inline',
                  },
                }}
              >
                Кабинет
              </span>
            </SpanHoverUnderline>
          </div>
        </div>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div
          onClick={() => setIsPopupMenuOpen(!isPopupMenuOpen)}
          css={{
            [DEVICE_SCREENS_MIN_STRING.sm]: {
              display: 'none',
            },
            display: 'flex',
            width: '54px',
            justifyContent: 'center',
          }}
        >
          <span css={{ display: 'flex', alignItems: 'center', height: '100%' }}>
            {isPopupMenuOpen ? (
              <CloseSVG width='20px' height='20px' />
            ) : (
              <MenuSVG width='20px' height='20px' />
            )}
          </span>
        </div>
      </Container>
      {isPopupMenuOpen && !isLoading && (
        <div
          css={{
            [DEVICE_SCREENS_MIN_STRING.sm]: {
              display: 'none',
            },
            width: '100%',
            height: '100%',
            maxHeight: 'calc(100vh - 60px)',
            minHeight: 'calc(100vh - 60px)',
            backgroundColor: theme.colors.secondary,
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
        >
          <ul
            css={{
              paddingTop: '13px',
              paddigBottom: '13px',
              backgroundColor: theme.colors.white,
              fontSize: '16px',
              color: theme.colors.textSecondary,
            }}
          >
            {categoriesForHeader.map((category) => (
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
              <li
                css={{
                  fontFamily: 'Montserrat',
                  padding: '13px',
                  paddingRight: '20px',
                  paddingLeft: '22px',
                  width: '81.5%',
                  borderBottom: '1px solid #E9E9E9',
                  cursor: 'pointer',
                  fontWeight: 700,
                }}
                key={category.id}
                onClick={() => setIsPopupMenuOpen(!isPopupMenuOpen)}
              >
                <Link
                  css={{
                    minWidth: '100%',
                    display: 'block',
                  }}
                  href={[
                    '',
                    currentCity.url,
                    'catalog',
                    category.slug,
                    '',
                  ].join('/')}
                >
                  {category.title}
                </Link>
              </li>
            ))}
          </ul>
          <ul
            css={{
              paddingTop: '15px',
              paddingBottom: '15px',
              backgroundColor: theme.colors.secondary,
              fontFamily: 'Golos',
              fontWeight: 500,
              fontSize: '16px',
              letterSpacing: '1px',
            }}
          >
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <li
              css={{
                padding: '15px',
                paddingLeft: '16px',
                width: '81.5%',
                borderBottom: '1px solid white',
                cursor: 'pointer',
              }}
              onClick={() => setIsPopupMenuOpen(!isPopupMenuOpen)}
            >
              <Link
                css={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  textTransform: 'uppercase',
                }}
                href={['', currentCity.url, 'catalog', ''].join('/')}
              >
                <CatalogSVG
                  css={{ width: 16, height: 16, marginRight: '10px' }}
                />
                Каталог
              </Link>
            </li>
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <li
              css={{
                padding: '15px',
                paddingLeft: '16px',
                cursor: 'pointer',
                width: '81.5%',
                borderBottom: '1px solid white',
              }}
              onClick={() => setIsPopupMenuOpen(!isPopupMenuOpen)}
            >
              <Link
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                css={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  textTransform: 'uppercase',
                }}
                href={['', 'search', ''].join('/')}
              >
                <SearchSVG
                  css={{ width: 16, height: 16, marginRight: '10px' }}
                />
                Поиск
              </Link>
            </li>
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <li
              css={{
                padding: '15px',
                paddingLeft: '16px',
                cursor: 'pointer',
                width: '81.5%',
                borderBottom: '1px solid white',
              }}
              onClick={() => {
                if (context) {
                  setIsPopupMenuOpen(!isPopupMenuOpen);
                  context.setModalState({
                    content: (
                      <ModalContentWrapper>
                        <ModalTitleWrapper
                          setModalState={context.setModalState}
                        >
                          <ModalTitle>{}</ModalTitle>
                        </ModalTitleWrapper>
                        <ModalContent>
                          <div>
                            <CalendarComponent
                              hideModal={() => {
                                context.setModalState({
                                  show: false,
                                  content: '',
                                });
                              }}
                              currentCity={currentCity}
                              setIsShowCalendar={setIsShowCalendar}
                            />
                          </div>
                        </ModalContent>
                      </ModalContentWrapper>
                    ),
                    show: true,
                  });
                }
              }}
            >
              <a
                css={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  textTransform: 'uppercase',
                }}
              >
                <CalendarSVG
                  css={{ width: 16, height: 16, marginRight: '10px' }}
                />
                Календарь
              </a>
            </li>
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <li
              css={{
                padding: '15px',
                paddingLeft: '16px',
                cursor: 'pointer',
                width: '81.5%',
                borderBottom: '1px solid white',
              }}
              onClick={() => setIsPopupMenuOpen(!isPopupMenuOpen)}
            >
              <button
                type='button'
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                css={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  textTransform: 'uppercase',
                }}
                onClick={onFavoriteClick}
              >
                <FavoriteSVG
                  css={{ width: 16, height: 16, marginRight: '10px' }}
                />
                Избранное
              </button>
            </li>
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <li
              css={{
                padding: '15px',
                paddingLeft: '16px',
                cursor: 'pointer',
                width: '81.5%',
                borderBottom: '1px solid white',
              }}
              onClick={() => setIsPopupMenuOpen(!isPopupMenuOpen)}
            >
              <Link
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                css={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  textTransform: 'uppercase',
                }}
                href={['', currentCity.url, 'compilations', ''].join('/')}
              >
                <CompilationSVG
                  css={{ width: 16, height: 16, marginRight: '10px' }}
                />
                Подборки
              </Link>
            </li>
            <li
              css={{
                padding: '15px',
                paddingLeft: '16px',
                cursor: 'pointer',
                width: '81.5%',
              }}
            >
              <a
                css={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  textTransform: 'uppercase',
                }}
                onClick={() => {
                  if (context && !authorization.isAuthenticated) {
                    context.setModalState({
                      content: (
                        <LoginModalModalForm
                          setModalState={context.setModalState}
                          currentCity={currentCity}
                        />
                      ),
                      show: true,
                    });
                  } else {
                    router.push('/profile/');
                  }
                }}
              >
                <PersonSVG
                  css={{ width: 16, height: 16, marginRight: '10px' }}
                />
                {authorization.isAuthenticated
                  ? 'Профиль'
                  : 'Вход или регистрация'}
              </a>
            </li>
          </ul>
          <div
            css={{
              display: 'flex',
              gap: '10px',
              backgroundColor: theme.colors.secondary,
              paddingLeft: '30px',
              paddingBottom: '8px',
            }}
          >
            <a href='https://vk.com/gorbilet'>
              <VKSVG />
            </a>
            <a href='https://t.me/gorbilet'>
              <TelegramSVG />
            </a>
          </div>
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              backgroundColor: theme.colors.secondary,
              paddingLeft: '30px',
              paddingBottom: '8px',
            }}
          >
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <span
              onClick={() => setIsQuestionOpen(!isQuestionOpen)}
              css={{
                fontSize: '18px',
                display: 'flex',
                alignItems: 'center',
                fontStyle: 'normal',
                fontWeight: '700',
                lineHeight: '22px',
                cursor: 'pointer',
                letterSpacing: '0em',
              }}
            >
              Задать вопрос
              <AngleDownSVG />
            </span>
            {isQuestionOpen && (
              <div css={{ display: 'flex', gap: '10px' }}>
                <a href='https://vk.com/gorbilet'>
                  <VKSVG />
                </a>
                <a href='https://t.me/gorbilet'>
                  <TelegramSVG />
                </a>
                <a href='https://wa.me/711192558'>
                  <WhatsAppSVG />
                </a>
                <a href='https://t.me/gorbilet'>
                  <EmailSVG />
                </a>
              </div>
            )}
          </div>
        </div>
      )}
    </header>
  );
}
