import { useRouter } from 'next/router';
import Cookies from 'js-cookie';
import { useModal } from '../providers/modal';
import { initialModalState } from '../models/modal';
import { City } from '../models';

export const useCitySelect = ({
  cities,
  currentCity,
}: {
  cities: { results: City[] };
  currentCity: City;
}) => {
  const router = useRouter();
  function CitySelect() {
    const context = useModal();
    if (!context) {
      return null;
    }
    return (
      <ul>
        {cities.results.map((city) => (
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
          <li
            key={city.id}
            css={{
              '&:hover': {
                color: '#f44336',
                cursor: 'pointer',
              },
              listStyleType: 'none',
              fontSize: '16px',
              transition: '0.3s',
              color: city.id === currentCity.id ? '#f44336' : 'black',
            }}
            onClick={() => {
              localStorage.setItem('current-city', city.url);
              Cookies.remove('current-city');
              Cookies.set('current-city', city.url);
              router.push(`/${city.url}/`).then(() => {
                context.setModalState(initialModalState);
              });
            }}
          >
            {city.title}
          </li>
        ))}
      </ul>
    );
  }
  return {
    CitySelect,
  };
};
