export const EMAIL_REGEXP = /^[\w\-.+]+@([\wа-я-]+\.)+[\wа-я-]{2,3}$/i;
export const NUMBERS_REGEXP = /^\d+$/i;

export const PHONE_REGEXP = /^((\+7|7|8)[0-9]{10})?(9[0-9]{9})?$/;

export const months = [
  { id: '01', title: 'Январь' },
  { id: '02', title: 'Февраль' },
  { id: '03', title: 'Март' },
  { id: '04', title: 'Апрель' },
  { id: '05', title: 'Май' },
  { id: '06', title: 'Июнь' },
  { id: '07', title: 'Июль' },
  { id: '08', title: 'Август' },
  { id: '09', title: 'Сентябрь' },
  { id: '10', title: 'Октябрь' },
  { id: '11', title: 'Ноябрь' },
  { id: '12', title: 'Декабрь' },
];

export const smartCaptchaYandexScriptUrl =
  'https://smartcaptcha.yandexcloud.net/captcha.js';

export const COMPILATION_EVENTS_COUNT = 20;
