import styled from '@emotion/styled';
import { ReactNode } from 'react';
import { DEVICE_SCREENS_MIN_STRING } from '../../lib/consts';

const Container = styled(
  ({
    children,
    className,
    ...props
  }: {
    children: ReactNode;
    className?: string;
  }) => (
    <div className={className || ''} {...props}>
      {children}
    </div>
  )
)({
  position: 'relative',
  marginLeft: 'auto',
  marginRight: 'auto',
  paddingLeft: '0',
  paddingRight: '0',
  maxWidth: '100%',
  [DEVICE_SCREENS_MIN_STRING.sm]: {
    width: '540px',
  },
  [DEVICE_SCREENS_MIN_STRING.md]: {
    width: '720px',
  },
  [DEVICE_SCREENS_MIN_STRING.lg]: {
    width: '960px',
  },
  [DEVICE_SCREENS_MIN_STRING.xl]: {
    width: '1140px',
  },
  [DEVICE_SCREENS_MIN_STRING.xxl]: {
    width: '1280px',
  },
});

export { Container };
