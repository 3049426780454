import * as yup from 'yup';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  Config,
  Connect,
  ConnectEvents,
  VKAuthButtonCallbackResult,
} from '@vkontakte/superappkit';
import { useRouter } from 'next/router';
import { City, ModalState } from '../../models';
import email from '../../utils/validators';
import { useAuthorization } from '../../providers/authorization';
import {
  EMAIL_REGEXP,
  PHONE_REGEXP,
  smartCaptchaYandexScriptUrl,
} from '../../utils/consts';
import { http } from '../../lib/api/http';
import { V2, V3 } from '../../lib/api/urls';
import {
  ModalContent,
  ModalContentWrapper,
  ModalTitle,
  ModalTitleWrapper,
} from '../common/Modal';
import { Form } from '../styled/Form';
import Input from '../form/input';
import { useLoading } from '../../providers/loading';
import Checkbox from '../form/Checkbox';
import { ConfigTypes } from '../../models/config';

const formTypes = {
  login: 'login',
  register: 'register',
  recovery: 'recovery',
};

yup.addMethod(yup.string, 'username', email);

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .required('Поле обязательно')
    .username('Неверно введён email'),
  password: yup.string().when('type', {
    is: (value: string) => value === formTypes.login,
    then: (schema) => schema.required('Поле обязательно'),
  }),
});

function ModalFormTitle({
  activeForm,
  setActiveForm,
}: {
  activeForm: string;
  setActiveForm: Dispatch<SetStateAction<string>>;
}) {
  return (
    <>
      {activeForm === formTypes.login && 'Войти'}
      {activeForm === formTypes.register && 'Создать профиль'}
      {activeForm === formTypes.recovery && 'Забыли пароль?'}
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <span
        onClick={() =>
          setActiveForm(
            activeForm === formTypes.login
              ? formTypes.register
              : formTypes.login
          )
        }
        css={{
          color: 'rgba(0, 0, 0, 0.25)',
          borderBottom: '1px dashed rgba(0, 0, 0, 0.1)',
          cursor: 'pointer',
          position: 'relative',
          marginLeft: 15,
          '&:before': {
            content: '"|"',
            position: 'absolute',
            left: -12,
            color: 'rgba(0, 0, 0, 0.3)',
            fontWeight: 400,
          },
        }}
      >
        {activeForm === formTypes.login && 'Создать профиль'}
        {activeForm === formTypes.register && 'Войти'}
        {activeForm === formTypes.recovery && 'Войти'}
      </span>
    </>
  );
}

function LoginModalModalForm({
  setModalState,
  currentCity,
}: {
  setModalState: Dispatch<SetStateAction<ModalState>>;
  currentCity: City;
}) {
  const [activeForm, setActiveForm] = useState(formTypes.login);
  const [error, setError] = useState({ message: '', status: false });
  const [popupState, setPopupState] = useState({
    show: false,
    title: '',
    description: '',
  });
  const router = useRouter();
  const {
    register,
    reset,
    formState: { errors, isValid, isSubmitted },
    setError: setFormError,
    watch,
    control,
    handleSubmit,
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
      subscribe: true,
      type: formTypes.login,
    },
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onSubmit',
  });
  const auth = useAuthorization();
  const { updateLoading } = useLoading();
  const [showCaptcha, setShowCaptcha] = useState(false);
  const emailValue = watch('email');
  function onSubmit(data: {
    email: string;
    password: string;
    subscribe: boolean;
  }) {
    let username = data.email;
    const referalCode =
      typeof localStorage !== 'undefined' ? localStorage.referalCode : null;
    const payload: {
      referalCode: string;
      phone_number?: string;
      token?: string;
      email?: string;
    } = {
      ...{ referalCode },
    };
    const isPhoneNumber = new RegExp(PHONE_REGEXP).test(username);
    function waitForElement(selector: string) {
      // eslint-disable-next-line consistent-return
      return new Promise((resolve) => {
        if (document.querySelector(selector)) {
          // eslint-disable-next-line no-promise-executor-return
          return resolve(document.querySelector(selector));
        }

        const observer = new MutationObserver(() => {
          if (document.querySelector(selector)) {
            resolve(document.querySelector(selector));
            observer.disconnect();
          }
        });

        observer.observe(document.body, {
          childList: true,
          subtree: true,
        });
      });
    }
    const { password } = data;
    switch (activeForm) {
      case formTypes.login:
        if (isPhoneNumber) {
          username = username.slice(-10);
        }
        if (auth.login) {
          updateLoading(true);
          auth
            .login(username, password)
            .then(() => {
              setModalState({
                show: false,
                content: '',
              });
            })
            .catch((apiError) => {
              if (
                apiError.detail ===
                'No active account found with the given credentials'
              ) {
                setFormError('email', {
                  type: 'FieldError',
                  message: 'Неверный e-mail или пароль',
                });
              }
            })
            .finally(() => updateLoading(false));
        }
        break;
      case formTypes.register:
        if (isPhoneNumber) {
          payload.phone_number = username.slice(-10);
        }
        if (new RegExp(EMAIL_REGEXP).test(username)) {
          payload.email = username;
        }
        updateLoading(true);
        http.get(V2.config, {}).then((config: ConfigTypes) => {
          const checkCaptcha = !!config.find(
            (item) => item.name === 'CHECK_CAPTCHA_FOR_V3_SMS'
          )?.value;
          if (checkCaptcha && isPhoneNumber) {
            updateLoading(false);
            setTimeout(() => {
              const captchaScript = document.createElement('script');
              captchaScript.setAttribute('src', smartCaptchaYandexScriptUrl);
              captchaScript.setAttribute('id', 'captcha-script');
              document.head.appendChild(captchaScript);
            }, 0);
            setShowCaptcha(true);
            waitForElement('[name=smart-token]').then(() => {
              const getValue = () => {
                const hiddenInputEl =
                  document.querySelector<HTMLInputElement>(
                    '[name=smart-token]'
                  );
                if (hiddenInputEl) {
                  const valueOfToken = hiddenInputEl.value;
                  if (valueOfToken.length > 0) {
                    payload.token = valueOfToken;
                    updateLoading(true);
                    http
                      .post(V3.register, payload)
                      .then((response) => {
                        if (response.status === 201) {
                          setPopupState({
                            show: true,
                            title: response.data.popup.title,
                            description: response.data.popup.description,
                          });
                        }
                        if (response.status === 400) {
                          setError({
                            status: true,
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            message: Object.values(response.data)[0].join(''),
                          });
                        }
                      })
                      .finally(() => {
                        setShowCaptcha(false);
                        updateLoading(false);
                      });
                  } else {
                    setTimeout(getValue, 800);
                  }
                }
              };
              setTimeout(getValue, 800);
            });
          } else {
            http
              .post(V3.register, payload)
              .then((response) => {
                if (response.status === 201) {
                  setPopupState({
                    show: true,
                    title: response.data.popup.title,
                    description: response.data.popup.description,
                  });
                }
                if (response.status === 400) {
                  setError({
                    status: true,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    message: Object.values(response.data)[0].join(''),
                  });
                }
              })
              .finally(() => updateLoading(false));
          }
        });
        if (data.subscribe && new RegExp(EMAIL_REGEXP).test(username)) {
          http.post(V2.subscriptions, {
            email: payload.email,
            source: 'subscriptionBlock',
            city: currentCity.remote_id,
          });
        }
        break;
      case formTypes.recovery:
        if (isPhoneNumber) {
          username = username.slice(-10);
        }
        updateLoading(true);
        http.get(V2.config, {}).then((config: ConfigTypes) => {
          const checkCaptcha = !!config.find(
            (item) => item.name === 'CHECK_CAPTCHA_FOR_V3_SMS'
          )?.value;
          if (checkCaptcha && isPhoneNumber) {
            updateLoading(false);
            setTimeout(() => {
              const captchaScript = document.createElement('script');
              captchaScript.setAttribute('src', smartCaptchaYandexScriptUrl);
              captchaScript.setAttribute('id', 'captcha-script');
              document.head.appendChild(captchaScript);
            }, 0);
            setShowCaptcha(true);
            waitForElement('[name=smart-token]').then(() => {
              const getValue = () => {
                const hiddenInputEl =
                  document.querySelector<HTMLInputElement>(
                    '[name=smart-token]'
                  );
                if (hiddenInputEl) {
                  const valueOfToken = hiddenInputEl.value;
                  if (valueOfToken.length > 0) {
                    updateLoading(true);
                    http
                      .post(V3.recovery, { username, token: valueOfToken })
                      .then((response) => {
                        if (response.status === 201) {
                          setPopupState({
                            show: true,
                            title: response.data.popup.title,
                            description: response.data.popup.description,
                          });
                        }
                        if (response.status === 400) {
                          const errorValues = Object.values(response.data)[0];
                          if (Array.isArray(errorValues)) {
                            setFormError('email', {
                              type: 'FieldError',
                              message: errorValues.join(''),
                            });
                          } else {
                            setFormError('email', {
                              type: 'FieldError',
                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              // @ts-ignore
                              message: errorValues,
                            });
                          }
                        }
                      })
                      .finally(() => {
                        setShowCaptcha(false);
                        updateLoading(false);
                      });
                  } else {
                    setTimeout(getValue, 800);
                  }
                }
              };
              setTimeout(getValue, 800);
            });
          } else {
            http
              .post(V3.recovery, { username })
              .then((response) => {
                if (response.status === 201) {
                  setPopupState({
                    show: true,
                    title: response.data.popup.title,
                    description: response.data.popup.description,
                  });
                }
                if (response.status === 400) {
                  const errorValues = Object.values(response.data)[0];
                  if (Array.isArray(errorValues)) {
                    setFormError('email', {
                      type: 'FieldError',
                      message: errorValues.join(''),
                    });
                  } else {
                    setFormError('email', {
                      type: 'FieldError',
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      message: errorValues,
                    });
                  }
                }
              })
              .finally(() => updateLoading(false));
          }
        });
        break;
      default:
        break;
    }
  }
  useEffect(() => {
    reset({ email: '', password: '', type: activeForm, subscribe: true });
  }, [activeForm, reset]);

  const onAuthUserVK = (evt: VKAuthButtonCallbackResult) => {
    const { payload } = evt;
    if (payload && 'user' in payload) {
      const dataFromVK = {
        first_name: payload.user.first_name,
        last_name: payload.user.last_name,
        avatar: payload.user.avatar,
        uuid: payload.uuid,
        token: payload.token,
      };
      http.post(V2.exchangeTokenVK, dataFromVK).then(async (response) => {
        if (response.status === 200) {
          const data = {
            email: response.data.response.email,
            token: response.data.response.jwt_token,
            refresh: response.data.response.jwt_refresh_token,
          };
          if (localStorage.getItem('current-user')) {
            const userDataAnon = JSON.parse(
              localStorage.getItem('current-user') as string
            );
            const currentUser = JSON.stringify(data);
            localStorage.setItem('current-user', currentUser);
            if (userDataAnon.uuid) {
              await http.post(
                V2.userMerge,
                {
                  anon_user_uuid: userDataAnon.uuid,
                },
                null,
                {
                  contentType: 'json',
                  withTokenRequest: true,
                }
              );
            }
          } else {
            const currentUser = JSON.stringify(data);
            localStorage.setItem('current-user', currentUser);
          }
          window.location.href = '/profile/';
        }
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const initVkAuth = async () => {
      if (activeForm === formTypes.login) {
        const config = await http.get(V2.config, {});
        const vkAppId = config.find(
          (item: { name: string }) => item.name === 'VK_APP_ID'
        )?.value; // APP_ID приложения VK, в которое встраивается SDK
        const superAppToken = config.find(
          (item: { name: string }) => item.name === 'VK_SECRET_KEY'
        )?.value; // Токен авторизации для сервиса
        Config.init({ appId: Number(vkAppId), superAppToken });
        const buttonOneTap = Connect.buttonOneTapAuth({
          callback(evt) {
            const { type } = evt;
            if (!type) {
              return;
            }
            switch (type) {
              case ConnectEvents.OneTapAuthEventsSDK.LOGIN_SUCCESS:
                // eslint-disable-next-line consistent-return
                return onAuthUserVK(evt);
              // Для событий PHONE_VALIDATION_NEEDED и FULL_AUTH_NEEDED нужно открыть полноценный VK ID, чтобы пользователь дорегистрировался или валидировал телефон
              case ConnectEvents.OneTapAuthEventsSDK.FULL_AUTH_NEEDED:
                break;
              case ConnectEvents.OneTapAuthEventsSDK.PHONE_VALIDATION_NEEDED:
              case ConnectEvents.ButtonOneTapAuthEventsSDK.SHOW_LOGIN:
                // eslint-disable-next-line consistent-return
                return Connect.redirectAuth({
                  url: `${document.location.protocol}//${document.location.host}/vk-auth/`,
                  state: router.asPath,
                });
              case ConnectEvents.ButtonOneTapAuthEventsSDK.SHOW_LOGIN_OPTIONS:
                // Параметр screen: phone позволяет сразу открыть окно ввода телефона в VK ID
                // eslint-disable-next-line consistent-return
                return Connect.redirectAuth({
                  url: `${document.location.protocol}//${document.location.host}/vk-auth/`,
                  state: router.asPath,
                  screen: 'phone',
                });
              default:
                break;
            }
          },
          options: {
            // showAlternativeLogin: true,
            showAgreements: false,
            showAgreementsDialog: true,
            displayMode: 'default',
          },
        });
        if (buttonOneTap) {
          // Фрэйм с кнопкой можно передать в любой элемент
          const elVkBlock = document.getElementById('vk-auth');
          if (elVkBlock) {
            elVkBlock.innerHTML = '';
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            elVkBlock.appendChild(buttonOneTap.getFrame());
          }
        }
      }
    };
    initVkAuth();
  }, [activeForm]);

  return popupState.show ? (
    <ModalContentWrapper>
      <ModalTitleWrapper setModalState={setModalState}>
        <ModalTitle>{popupState.title}</ModalTitle>
      </ModalTitleWrapper>
      <ModalContent>
        <div
          css={{
            p: {
              marginBottom: 24,
            },
          }}
          dangerouslySetInnerHTML={{ __html: popupState.description }}
        />
      </ModalContent>
    </ModalContentWrapper>
  ) : error.status ? (
    <ModalContentWrapper>
      <ModalTitleWrapper
        customClose={
          <span
            onClick={() => {
              reset();
              setError({ message: '', status: false });
            }}
            css={{
              fontSize: 14,
              fontWeight: 500,
              color: 'rgba(0, 0, 0, 0.3)',
              textDecoration: 'none',
              '&:hover': {
                color: 'rgba(0, 0, 0, 0.3)',
              },
            }}
          >
            назад
          </span>
        }
        setModalState={setModalState}
      >
        <ModalTitle>Ошибка</ModalTitle>
      </ModalTitleWrapper>
      <ModalContent>
        <div>{error.message}</div>
      </ModalContent>
    </ModalContentWrapper>
  ) : showCaptcha ? (
    <ModalContentWrapper>
      <ModalTitleWrapper setModalState={setModalState}>
        <ModalTitle>{}</ModalTitle>
      </ModalTitleWrapper>
      <ModalContent>
        <div
          id='captcha-container'
          className='smart-captcha'
          data-sitekey='ysc1_KVkwjUyKmMF4cuEMAqHqWCKyDwdipjleryCwEwor0abcfd95'
          css={{
            height: 106,
          }}
        />
      </ModalContent>
    </ModalContentWrapper>
  ) : (
    <ModalContentWrapper>
      <ModalTitleWrapper setModalState={setModalState}>
        <ModalTitle>
          <ModalFormTitle
            activeForm={activeForm}
            setActiveForm={setActiveForm}
          />
        </ModalTitle>
      </ModalTitleWrapper>
      <ModalContent>
        {activeForm === formTypes.recovery && (
          <div
            css={{
              paddingBottom: '1em',
              paddingTop: 0,
              fontWeight: 400,
              color: '#333',
            }}
          >
            Пожалуйста, укажите телефон или e-mail, который вы использовали при
            регистрации и мы пришлем туда новый пароль.
          </div>
        )}
        {activeForm === formTypes.register && (
          <div
            css={{
              paddingBottom: '1em',
              paddingTop: 0,
              fontWeight: 400,
              color: '#333',
            }}
          >
            Введите свой телефон или e-mail, на него придет логин и пароль для
            дальнейшего входа на сайт.
          </div>
        )}
        {activeForm === formTypes.login && (
          <div>
            Если вы пользуетесь мобильной версией приложения и у вас нет пароля
            или вы его забыли, то можете его восстановить.
          </div>
        )}
        <Form
          css={{
            'div:first-child': {
              paddingTop: '1em',
            },
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Input
            text='Телефон или e-mail'
            control={control}
            name='email'
            registerProps={register('email')}
            error={
              activeForm !== formTypes.login && errors.email && isSubmitted
            }
            errorMessage={errors.email?.message}
          />
          {activeForm === formTypes.register &&
            new RegExp(EMAIL_REGEXP).test(emailValue) && (
              <span
                css={{
                  display: 'block',
                  padding: '1.5em 0',
                }}
              >
                <Checkbox
                  id='terms'
                  name='subscribe'
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  control={control}
                  labelText='подписаться на рассылку'
                  registerProps={register('subscribe')}
                />
              </span>
            )}
          {activeForm === formTypes.login && (
            <Input
              text='Пароль'
              type='password'
              control={control}
              name='password'
              registerProps={register('password')}
              error={
                activeForm === formTypes.login && errors.email && isSubmitted
              }
              errorMessage={errors.email?.message}
            />
          )}
          <div
            css={{
              paddingTop: '1em',
              textAlign: 'center',
            }}
          >
            <button
              type='submit'
              disabled={!isValid}
              css={{
                fontFamily: 'Golos',
                fontSize: 16,
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: 16,
                letterSpacing: '0em',
                border: 'none',
                cursor: 'pointer',
                textDecoration: 'none',
                color: '#fff',
                height: '2.56em',
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '1.28em',
                padding: '0 1.6em',
                backgroundColor: '#ff4a2e',
                backgroundImage:
                  'linear-gradient(120deg, #ff4a2e 0%, #de2839 100%)',
                backgroundSize: '500% 500%',
                margin: '0 auto',
                animation: 'move-background 60s linear infinite',
                '&:disabled': {
                  backgroundColor: '#BFC1C3',
                  backgroundImage:
                    'linear-gradient(120deg, #BFC1C3 0%, #d4d7d2 100%)',
                  cursor: 'default',
                },
              }}
            >
              {activeForm === formTypes.login && 'Войти'}
              {activeForm === formTypes.register && 'Зарегистрироваться'}
              {activeForm === formTypes.recovery && 'Выслать пароль'}
            </button>
          </div>
          {activeForm === formTypes.login && (
            <div css={{ display: 'flex', justifyContent: 'center' }}>
              <div css={{ width: 300 }} id='vk-auth' />
            </div>
          )}
          {activeForm === formTypes.login && (
            <div
              css={{
                padding: '1em 0',
                textAlign: 'center',
              }}
            >
              {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
              <span
                onClick={() => setActiveForm(formTypes.recovery)}
                css={{
                  fontWeight: 400,
                  cursor: 'pointer',
                  color: '#ccc',
                  borderBottom: '1px dashed rgba(204, 204, 204, 0.7)',
                }}
              >
                Забыли пароль?
              </span>
            </div>
          )}
        </Form>
      </ModalContent>
    </ModalContentWrapper>
  );
}

export default LoginModalModalForm;
